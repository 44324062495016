import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useReferralStore = defineStore('referral', () => {
    const { api } = useAxios();
    const DialogVisibility = ref(false);
    const CanRequestCommission = ref(false);
    const EnrolledReferrals = ref(0);
    const TotalCommissions = ref(0);
    const TotalReferrals = ref(0);
    const DialogGetRewardVisibility = ref(false);
    const DataList = ref([]);
    const EarnedDataList = ref([]);
    const totalPagination = ref(null);
    const EarnedTotalPagination = ref(null);
    const loadingStatistics = ref(true);
    const loadingData = ref(true);
    const PaginationFirst = ref(0);
    const EarnedPaginationFirst = ref(0);
    const showAppliedApps = ref(false);
    const userAppliedApps = ref({});

    //Get All Referrals
    const getListData = async () => {
        await api.get(`/referral?per_page=5&page=1`).then(res => {
            loadingData.value = true;
            if (res.status === 200) {
                DataList.value = res.data?.data;
                totalPagination.value = res.data?.total;
                PaginationFirst.value = 0;
            }
            loadingData.value = false;
        });
    };

    //Get Earned Referrals
    const getEarnedListData = async () => {
        await api.get(`/referral/earned?per_page=5&page=1`).then(res => {
            if (res.status === 200) {
                EarnedDataList.value = res.data?.data;
                EarnedTotalPagination.value = res.data?.total;
                EarnedPaginationFirst.value = 0;
            }
        });
    };

    //Get Statistics
    const getStatistics = async () => {
        loadingStatistics.value = true;

        await api.get(`/referral/statistics`).then(res => {
            if (res.status === 200) {
                loadingStatistics.value = false;
                CanRequestCommission.value = res.data?.data?.can_request_commission;
                EnrolledReferrals.value = res.data?.data?.enrolled_referrals;
                TotalCommissions.value = res.data?.data?.total_commissions;
                TotalReferrals.value = res.data?.data?.total_referrals;
            }
        });
    };

    return {
        DialogVisibility,
        DialogGetRewardVisibility,
        DataList,
        totalPagination,
        loadingData,
        PaginationFirst,
        CanRequestCommission,
        EnrolledReferrals,
        TotalCommissions,
        TotalReferrals,
        loadingStatistics,
        EarnedDataList,
        EarnedTotalPagination,
        EarnedPaginationFirst,
        showAppliedApps,
        userAppliedApps,
        getListData,
        getStatistics,
        getEarnedListData,
    };
});
